<template>
    <div
        class="alert-dismiss"
        @click="action">
        <styled-tooltip
            v-if="!item.dismissed"
            :max-width="185"
            content-class="text-center"
            position="top"
            nudge-top="10">
            <template #content>
                This will "Dismiss" the alert type for this account for the agency
            </template>
            <icon
                name="alert"
                size="18" />
        </styled-tooltip>
        <styled-tooltip
            v-else-if="!enablingActive"
            :max-width="185"
            content-class="text-center"
            position="top"
            nudge-top="10">
            <template #content>
                This will cancel this dismissed alert and show as normal
            </template>
            <icon
                name="alert-muted"
                size="18" />
        </styled-tooltip>
        <action-button
            v-else
            size="14"
            icon=""
            :loading="true" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { mapState } from 'vuex';

export default {
    components: {
        Icon,
        ActionButton,
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            enablingActive: (state) => state.monitoring.enablingAlertIsActive,
        })
    },
    methods: {
        action() {
            if (!this.enablingActive) {
                this.$emit('callback');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.alert-dismiss {
    cursor: pointer;
}
</style>